import React from "react";
import { Helmet } from "react-helmet-async";

const Privacy = () => {
  return (
    <div className="w-full">
      <Helmet>
        <title>Privacy Policy | Nurovant AI</title>
        <meta
          name="description"
          content="Nurovant AI Privacy Policy - Learn about how we collect, use, and protect your personal information."
        />
      </Helmet>
      <div className="w-full p-5 sm:p-10 md:p-20 lg:px-40 xl:px-60 flex flex-col justify-center items-center text-center gap-10">
        <div className="rounded-full p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
          <p className="block bg-white cursor-text p-2 px-5 rounded-full text-sm font-semibold capitalize">
            Privacy Policy 🔒
          </p>
        </div>
        <h1 className="text-5xl font-bold text-[#161617]">
          Nurovant Privacy Policy
        </h1>
      </div>

      <div className="w-full px-5 sm:px-10 md:px-20 lg:px-40 xl:px-60 pb-20">
        <div className="w-full max-w-4xl mx-auto">
          <p className="text-base text-[#57585A] mb-8">
            <strong>Effective Date:</strong> 01/07/2024
          </p>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              1. Introduction
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              Welcome to Nurovant AI ("Nurovant", "we", "us", "our"). We value
              your privacy and are committed to protecting your personal data.
              This Privacy Policy outlines the types of information we collect,
              how we use it, and the measures we take to safeguard your data.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              2. Information We Collect
            </h2>
            <p className="text-base text-[#57585A] mb-2">
              <strong>a. Personal Information:</strong> When you register for our
              services, we may collect personal information such as your name,
              email address, contact information, and payment details.
            </p>
            <p className="text-base text-[#57585A] mb-2">
              <strong>b. Usage Data:</strong> We collect data on how you
              interact with our app and website, including but not limited to, IP
              address, browser type, pages visited, and the time and date of your
              visit.
            </p>
            <p className="text-base text-[#57585A] mb-2">
              <strong>c. Device Information:</strong> Information about the
              device you use to access our services, including device type,
              operating system, and unique device identifiers.
            </p>
            <p className="text-base text-[#57585A] mb-2">
              <strong>d. Academic Information:</strong> Data related to your
              academic activities and materials, such as the content you upload
              for recaps, flashcards, and discussions, as well as coding projects
              and citations.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              3. How We Use Your Information
            </h2>
            <p className="text-base text-[#57585A] mb-2">
              <strong>a. To Provide and Improve Services:</strong> We use the
              collected information to deliver, maintain, and improve our
              services, including generating AI-driven recaps, flashcards, and
              coding assistance, as well as facilitating discussions,
              translations, and citation management.
            </p>
            <p className="text-base text-[#57585A] mb-2">
              <strong>b. Communication:</strong> We may use your personal
              information to contact you with newsletters, marketing or
              promotional materials, and other information that may be of
              interest to you.
            </p>
            <p className="text-base text-[#57585A] mb-2">
              <strong>c. Compliance and Legal Obligations:</strong> To comply
              with legal obligations, resolve disputes, and enforce our
              agreements.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              4. Sharing Your Information
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              We do not sell your personal information to third parties. We may
              share your information with:
            </p>
            <p className="text-base text-[#57585A] mb-2">
              <strong>a. Service Providers:</strong> Third-party vendors,
              consultants, and other service providers who perform services on
              our behalf.
            </p>
            <p className="text-base text-[#57585A] mb-2">
              <strong>b. Legal Requirements:</strong> If required by law or in
              response to valid requests by public authorities (e.g., a court or
              a government agency).
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              5. Data Security
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              We implement appropriate technical and organizational measures to
              protect your personal data against accidental or unlawful
              destruction, loss, alteration, unauthorized disclosure, or access.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              6. Your Data Protection Rights
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              You have the right to access, rectify, or erase the personal data
              we hold about you. You also have the right to object to or restrict
              certain processing of your data. To exercise these rights, please
              contact us at the information provided in the "Contact Us" section.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              7. Changes to This Privacy Policy
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page
              and updating the effective date at the top.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              8. Contact Us
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              If you have any questions about this Privacy Policy, please contact
              us at:
            </p>
            <p className="text-base text-[#57585A] mb-4">
              <strong>Nurovant AI</strong>
              <br />
              Email: <a href="mailto:support@nurovant.com" className="text-[#4970FC] hover:underline">support@nurovant.com</a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
