import React from "react";
import { Helmet } from "react-helmet-async";

const Terms = () => {
  return (
    <div className="w-full">
      <Helmet>
        <title>Terms of Service | Nurovant AI</title>
        <meta
          name="description"
          content="Nurovant AI Terms of Service - Read about the terms and conditions for using our services."
        />
      </Helmet>
      <div className="w-full p-5 sm:p-10 md:p-20 lg:px-40 xl:px-60 flex flex-col justify-center items-center text-center gap-10">
        <div className="rounded-full p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
          <p className="block bg-white cursor-text p-2 px-5 rounded-full text-sm font-semibold capitalize">
            Terms of Service 📝
          </p>
        </div>
        <h1 className="text-5xl font-bold text-[#161617]">
          Terms Of Use
        </h1>
      </div>

      <div className="w-full px-5 sm:px-10 md:px-20 lg:px-40 xl:px-60 pb-20">
        <div className="w-full max-w-4xl mx-auto">
          <p className="text-base text-[#57585A] mb-8">
            <strong>Effective Date:</strong> 01/07/2024
          </p>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              1. Acceptance of Terms
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              By accessing and using Nurovant AI ("Nurovant", "we", "us", "our") services, you agree to be bound by these Terms of Use ("Terms"). If you do not agree with any part of these Terms, you must not use our services.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              2. Description of Services
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              Nurovant provides various educational tools and services, including but not limited to AI-generated recaps, flashcards, discussion facilitation, coding tutoring, translation features, and citation management. These services are accessible via our website and mobile applications.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              3. Eligibility
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              You must be at least 18 years old to use our services. By using Nurovant, you represent and warrant that you have the right, authority, and capacity to enter into these Terms and to abide by all of the terms and conditions set forth herein.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              4. Account Registration
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              To access certain features of our services, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your account password and for all activities or actions under your account.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              5. User Conduct
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              You agree not to use Nurovant to:
            </p>
            <ul className="list-disc pl-6 mb-4 space-y-2">
              <li className="text-base text-[#57585A]">
                Violate any applicable law or regulation.
              </li>
              <li className="text-base text-[#57585A]">
                Infringe the rights of any third party, including but not limited to intellectual property, privacy, and contractual rights.
              </li>
              <li className="text-base text-[#57585A]">
                Upload, post, or transmit any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.
              </li>
              <li className="text-base text-[#57585A]">
                Engage in any activity that could damage, disable, or impair the functionality of our services.
              </li>
            </ul>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              6. Intellectual Property
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              All content and materials available on Nurovant, including but not limited to text, graphics, website name, code, images, and logos, are the intellectual property of Nurovant and are protected by applicable copyright and trademark law. Any inappropriate use, including but not limited to the reproduction, distribution, display, or transmission of any content on this site is strictly prohibited unless specifically authorized by Nurovant.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              7. User Content
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              By submitting any content to Nurovant, you grant us a worldwide, non-exclusive, royalty-free, fully-paid, sublicensable, and transferable license to use, reproduce, modify, distribute, and display such content in connection with the services.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              8. Privacy
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              Your use of our services is also governed by our <a href="/privacy" className="text-[#4970FC] hover:underline">Privacy Policy</a>. Please review our Privacy Policy for information on how we collect, use, and disclose your personal information.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              9. Termination
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              We may terminate or suspend your account and access to our services immediately, without prior notice or liability, if you breach any of these Terms. Upon termination, your right to use our services will cease immediately.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              10. Disclaimers and Limitation of Liability
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              Our services are provided "as is" and "as available" without any warranties of any kind, either express or implied. Nurovant does not warrant that the services will be uninterrupted or error-free. In no event shall Nurovant, its affiliates, or their respective directors, employees, or agents be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of our services.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              11. Governing Law
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              These Terms shall be governed and construed in accordance with the laws of the state in which Nurovant is headquartered, without regard to its conflict of law provisions.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              12. Changes to Terms
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
            </p>
          </section>

          <section className="mb-10">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">
              13. Contact Us
            </h2>
            <p className="text-base text-[#57585A] mb-4">
              If you have any questions about these Terms, please contact us at:
            </p>
            <p className="text-base text-[#57585A] mb-4">
              <strong>Nurovant AI</strong>
              <br />
              Email: <a href="mailto:support@nurovant.com" className="text-[#4970FC] hover:underline">support@nurovant.com</a>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Terms;
