import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const ZoomIntegrationDocs = () => {
  return (
    <div className="w-full">
      <Helmet>
        <title>Nurovant AI for Meeting Recaps on Zoom | Documentation</title>
        <meta
          name="description"
          content="Learn how to add, use, and remove Nurovant AI for Meeting Recaps on Zoom with our step-by-step guide."
        />
      </Helmet>
      <div className="w-full p-5 sm:p-10 md:p-20 lg:px-40 xl:px-60 flex flex-col justify-center items-center text-center gap-10">
        <div className="rounded-full p-[1px] bg-gradient-to-b from-[#D8B4E240] to-[#4970FC40]">
          <p className="block bg-white cursor-text p-2 px-5 rounded-full text-sm font-semibold capitalize">
            Documentation 📚
          </p>
        </div>
        <h1 className="text-4xl md:text-5xl font-bold text-[#161617]">
          How to Add, Use, and Remove NUROVANT AI FOR MEETING RECAPS on Zoom
        </h1>
      </div>

      <div className="w-full px-5 sm:px-10 md:px-20 lg:px-40 xl:px-60 pb-20">
        <div className="w-full max-w-4xl mx-auto space-y-12">
          {/* Adding the App Section */}
          <section className="mb-10">
            <h2 className="text-3xl font-bold text-[#161617] mb-6 flex items-center">
              <span className="inline-flex justify-center items-center w-10 h-10 rounded-full bg-[#4970FC] text-white mr-3">1</span>
              Adding the App
            </h2>
            <div className="pl-14 space-y-6">
              <div className="space-y-3">
                <h3 className="text-xl font-bold text-[#161617]">Navigate to the Zoom App Marketplace:</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li className="text-base text-[#57585A]">
                    Go to <a href="https://marketplace.zoom.us" target="_blank" rel="noopener noreferrer" className="text-[#4970FC] hover:underline">Zoom App Marketplace</a>.
                  </li>
                  <li className="text-base text-[#57585A]">
                    Log in to your Zoom account if prompted.
                  </li>
                </ul>
              </div>

              <div className="space-y-3">
                <h3 className="text-xl font-bold text-[#161617]">Search for Nurovant AI for meeting recaps:</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li className="text-base text-[#57585A]">
                    Use the search bar to find Nurovant AI for meeting recap in the Zoom App Marketplace.
                  </li>
                </ul>
              </div>

              <div className="space-y-3">
                <h3 className="text-xl font-bold text-[#161617]">Install the App:</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li className="text-base text-[#57585A]">
                    Click on the app listing to view details.
                  </li>
                  <li className="text-base text-[#57585A]">
                    Click "Install" or "Add to Zoom" to initiate the installation process.
                  </li>
                  <li className="text-base text-[#57585A]">
                    Follow the prompts to authorize the app and grant necessary permissions.
                  </li>
                </ul>
              </div>
            </div>
          </section>

          {/* Using the App Section */}
          <section className="mb-10">
            <h2 className="text-3xl font-bold text-[#161617] mb-6 flex items-center">
              <span className="inline-flex justify-center items-center w-10 h-10 rounded-full bg-[#4970FC] text-white mr-3">2</span>
              Using the App
            </h2>
            <div className="pl-14 space-y-6">
              <div className="space-y-3">
                <h3 className="text-xl font-bold text-[#161617]">Initiate a Zoom Meeting:</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li className="text-base text-[#57585A]">
                    Start or join a Zoom meeting as usual through the Zoom desktop or mobile app.
                  </li>
                </ul>
              </div>

              <div className="space-y-3">
                <h3 className="text-xl font-bold text-[#161617]">Access Nurovant AI for meeting recap:</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li className="text-base text-[#57585A]">
                    Look for the integration panel or interface provided by Nurovant AI for meeting recap within the Zoom meeting environment.
                  </li>
                </ul>
              </div>

              <div className="space-y-3">
                <h3 className="text-xl font-bold text-[#161617]">Recording and Transcriptions:</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li className="text-base text-[#57585A]">
                    Use the app's features to record the meeting session.
                  </li>
                  <li className="text-base text-[#57585A]">
                    Optionally, enable AI-powered transcriptions to generate text summaries of the meeting.
                  </li>
                </ul>
              </div>

              <div className="space-y-3">
                <h3 className="text-xl font-bold text-[#161617]">Managing Settings:</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li className="text-base text-[#57585A]">
                    Adjust settings within the app interface as needed, such as recording preferences or transcription settings.
                  </li>
                </ul>
              </div>
            </div>
          </section>

          {/* Removing the App Section */}
          <section className="mb-10">
            <h2 className="text-3xl font-bold text-[#161617] mb-6 flex items-center">
              <span className="inline-flex justify-center items-center w-10 h-10 rounded-full bg-[#4970FC] text-white mr-3">3</span>
              Removing the App
            </h2>
            <div className="pl-14 space-y-6">
              <div className="space-y-3">
                <h3 className="text-xl font-bold text-[#161617]">Access Zoom App Marketplace:</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li className="text-base text-[#57585A]">
                    Navigate back to the <a href="https://marketplace.zoom.us" target="_blank" rel="noopener noreferrer" className="text-[#4970FC] hover:underline">Zoom App Marketplace</a>.
                  </li>
                </ul>
              </div>

              <div className="space-y-3">
                <h3 className="text-xl font-bold text-[#161617]">Manage Installed Apps:</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li className="text-base text-[#57585A]">
                    Click on your profile icon and select "Settings" from the dropdown menu.
                  </li>
                  <li className="text-base text-[#57585A]">
                    Go to the "Installed Apps" or "Installed Services" section.
                  </li>
                </ul>
              </div>

              <div className="space-y-3">
                <h3 className="text-xl font-bold text-[#161617]">Locate Nurovant AI for meeting recap:</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li className="text-base text-[#57585A]">
                    Find Nurovant AI for meeting recap in the list of installed apps or services.
                  </li>
                </ul>
              </div>

              <div className="space-y-3">
                <h3 className="text-xl font-bold text-[#161617]">Remove the App:</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li className="text-base text-[#57585A]">
                    Click on Nurovant AI for meeting recap to view details.
                  </li>
                  <li className="text-base text-[#57585A]">
                    Select "Uninstall" or "Remove" to revoke app access and permissions from your Zoom account.
                  </li>
                </ul>
              </div>
            </div>
          </section>

          {/* Need Help Section */}
          <section className="mt-16 p-8 bg-[#F5F5F5] rounded-2xl">
            <h2 className="text-2xl font-bold text-[#161617] mb-4">Need Help?</h2>
            <p className="text-base text-[#57585A] mb-4">
              For further assistance with adding, using, or removing Nurovant AI for meeting recap on Zoom, please contact our support team at{" "}
              <a href="mailto:support@nurovant.com" className="text-[#4970FC] hover:underline">
                support@nurovant.com
              </a>.
            </p>
            <div className="mt-6">
              <Link
                to="/contact"
                className="inline-flex items-center justify-center px-6 py-3 bg-[#4970FC] text-white font-bold rounded-full hover:bg-[#3A5AD9] transition-colors"
              >
                Contact Support
              </Link>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ZoomIntegrationDocs;
